.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.ant-form-item-label {
    float: left;
}


.login-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .login-container>div {
    width: 100%;
    height: 100%;
  }
  
  .login-container .login-box-container {
    width: 300px;
    height: 200px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
  
  .login-box-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .login-box {
    background-color: #eeeeee !important;
  }
  
  .login-box p,
  .login-box h1,
  .login-box h2,
  .login-box h3,
  .login-box span
  {
    text-align: center;
  }
  
  .login-box-subtitle
  {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .login-box-title
  {
    margin-top: 0px;
    text-transform: capitalize;
  }
  
  .login-box-button {
    width: 80%;
    margin-left: 10%;
    margin-right: 30%;
  }
  
  .center-div
  {
    position: absolute !important;
    margin: auto !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 250px;
    height: 300px;
    border-radius: 3px;
  }
  
  
  .particles
  {
    position: absolute !important;
    margin: auto !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }
.NoAccess {
    text-align: center;
}

.NoAccess-logo {
    padding: 30px;
    height: 30vmin;
 }

.NoAccess-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.NoAccess-link {
    color: #f1c40f;
}
