.NoAccess {
    text-align: center;
}

.NoAccess-logo {
    padding: 30px;
    height: 30vmin;
 }

.NoAccess-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.NoAccess-link {
    color: #f1c40f;
}